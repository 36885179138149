.exchange-box{
    /* background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
    border-radius: 16px; */
    box-shadow: 0px 0px 250px 0px rgba(6, 31, 17, 0.07);
    border-radius: 10px;
    background-color: #FFFFFF82;
    border: 1px solid #FFFFFF82;
    padding: 2em;
}

.exchange-box .nav-pills .nav-link.active{
    background-color: transparent;
    color: var(--tertiary-color);
    border-bottom: 3px solid var(--primary-color);
    border-radius: 0;
}

.exchange-box .nav-pills .nav-link{
    padding-left: 0;
    padding-right: 0;
    font-weight: 500;
    color: var(--tertiary-color);
    border-bottom: 3px solid var(--secondary-color);
    border-radius: 0;
}

.exchange-box .nav-pills{
    gap: 2em;
    justify-content: center;
    margin: auto;
}

.exchange-conversion-label{
    display: flex;
    align-items: center;
    gap: 1em;
}

.exchange-conversion-right-sec h5{
    margin-bottom: 0;
}

.exchange-conversion-box{
    margin-top: 2em;
}

.exchange-conversion-card{
    /* grid-template-columns: 325px auto; */
    /* grid-template-columns: 275px auto; */
    grid-template-columns: repeat(2, 1fr);
    display: grid;
}

.exchange-conversion-left-sec{
    width: 220px;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.border-theme-right{
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.border-theme-bottom{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.exchange-conversion-right-sec{
    padding-left: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    word-break: break-all;
}

.exchange-conversion-right-sec h5{
    font-size: 1em;
    font-weight: 400;
    color: var(--primary-color);
}

.exchange-conversion-right-sec h3{
    font-size: 1.8em;
    font-weight: 700;
    color: var(--tertiary-color);
    margin-bottom: 0;
}

.or-line {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--quaternary-color);
    text-transform: uppercase;
    padding: 30px 0;
}

.or-line:before, .or-line:after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    top: 50%;
    background: rgba(0, 0, 0, 0.1);
}

.or-line span {
    padding: 0 13px;
    background: var(--secondary-color);
    position: relative;
    z-index: 2;
}

.exchange-select-sec h3{
    font-size: 1.4em;
    font-weight: 700;
    color: var(--tertiary-color);
    margin-bottom: 0;
}

.exchange-btn-sec p a{
    color: #7D00FF;
}

.exchange-btn-sec{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    margin-top: 3em;
}

.exchange-btn-sec p{
    margin-bottom: 0;
}

.exchange-form{
    margin-top: 2em;
}

.error-card{
    display: flex;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;
}

.error-card span{
    color:#FF0000;
    font-size: 0.9em;
    font-weight: 400;
}

.exchange-form .form-label{
    font-size: 0.85em;
    font-weight: 600;
    color: var(--tertiary-color);
}

.exchange-form .form-control {
    border-left: 0;
    border-top: 0;
    border-radius: 0;
    border-right: 0;
    padding-left: 16px;
    font-size: 0.85em;
    background-color: #00000012!important;
}

.exchange-form .form-control::placeholder{
    color: #999;
    font-size: 0.9em;
}

.exchange-form .form-control:focus{
    box-shadow: none!important;
    outline: none!important;
    border-color: var(--primary-color);
}

.countdown-card{
    display: flex;
    align-items: center;
    gap:1em;
    justify-content: center;
  }
  
  .countdown-box{
    background-color: var(--quinary-color);
    padding: 0.5em;
    border-radius: 5px;
    color: var(--secondary-color);
    font-size: 1em;
    font-weight: 600;
  }
  
  .countdown-card span{
    line-height: 1.8;
      color: var(--tertiary-color);
      margin-bottom: 0;
      font-size: 1em;
      font-weight: 400;
  }
  
  .countdown-card p{
    line-height: 1.8;
      color: var(--tertiary-color);
      margin-bottom: 0;
      font-size: 1em;
      font-weight: 600;
  }

  .payment-processing-countdown-card{
    display: flex;
    align-items: center;
    gap: 1em;
    margin-top: 2em;
    justify-content: center;
  }

  .payment-text{
    line-height: 1.8;
    color: var(--tertiary-color);
    margin-bottom: 0;
    font-size: 1em;
    font-weight: 600;
  }

  .buy-processing-img-sec{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
  }

  .payment-failure-text{
    line-height: 1.8;
    color: #db1d1d;
    margin-bottom: 0;
    font-size: 1em;
    font-weight: 600;
  }

  .payment-success-text{
    line-height: 1.8;
    color: rgb(25,135,84);
    margin-bottom: 0;
    font-size: 1em;
    font-weight: 600;
  }

  .buy-success-img{
    max-width: 6em;
  }

  .buy-processing-img{
    max-width: 13em;
  }

  /* .buy-crypto-sec{
    margin-bottom: 4em;
  } */

  .buy-crypto-sec{
    padding: 2em 0;
  }